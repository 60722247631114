import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
} from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  CircularProgress,
  IconButton,
  TableSortLabel,
  Dialog,
  DialogContent,
  Fade,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { styled } from "@mui/system";
import * as queries from "../graphql/queries";

// ドコパヘッダーコンポーネント
import DocopaHeader from "../components/DocopaHeader.js";

// カスタムトランジションコンポーネントの定義
const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

// スタイル定義
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "200px", // 必要に応じて調整
  padding: theme.spacing(1), // 垂直方向のスペーシングをテーマに基づいて調整
  borderRight: `1px solid ${theme.palette.grey[400]}`, // デフォルトのボーダーを追加
}));

const ThumbnailTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRight: `1px solid ${theme.palette.grey[400]}`,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "40px", // 行の高さを減少
}));

// 検索フォームコンポーネントをメモ化
const SearchForm = React.memo(({ form, onChange, categories, onClear }) => (
  <Box mb={4}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="ID"
          name="id"
          value={form.id}
          onChange={onChange}
          variant="outlined"
          type="number" // 数値入力に制限
          InputProps={{ inputProps: { min: 0 } }} // 正の数のみ許可
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="キーワード"
          name="keyword"
          value={form.keyword}
          onChange={onChange}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={4} sx={{ display: "none" }}>
        <TextField
          fullWidth
          label="ユーザー"
          name="user"
          value={form.user}
          onChange={onChange}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          select
          label="カテゴリ"
          name="category"
          value={form.category}
          onChange={onChange}
          variant="outlined"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {categories.map((cat) => (
            <MenuItem key={cat.id} value={cat.name}>
              {cat.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            "& > button": { minWidth: "120px" }, // ボタンの最小幅を設定
          }}
        >
          {/* 検索ボタン（メインアクション） */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              flex: 2,
            }}
          >
            検索
          </Button>

          {/* クリアボタン */}
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClear}
            sx={{ flex: 1 }}
          >
            入力条件クリア
          </Button>
        </Box>
      </Grid>
    </Grid>
  </Box>
));

// 動画リストコンポーネントをメモ化
const VideoList = React.memo(
  ({
    videos,
    orderBy,
    order,
    onRequestSort,
    navigate,
    handleImageClick,
    isInitialState,
  }) => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ borderCollapse: "separate", borderSpacing: "0" }}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>操作</StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "id"}
                  direction={orderBy === "id" ? order : "asc"}
                  onClick={() => onRequestSort("id")}
                >
                  ID
                </TableSortLabel>
              </StyledTableCell>
              <ThumbnailTableCell>サムネイル</ThumbnailTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "title"}
                  direction={orderBy === "title" ? order : "asc"}
                  onClick={() => onRequestSort("title")}
                >
                  タイトル
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "user"}
                  direction={orderBy === "user" ? order : "asc"}
                  onClick={() => onRequestSort("user")}
                >
                  ユーザー
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "tag"}
                  direction={orderBy === "tag" ? order : "asc"}
                  onClick={() => onRequestSort("category")}
                >
                  カテゴリ
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "register_date"}
                  direction={orderBy === "register_date" ? order : "asc"}
                  onClick={() => onRequestSort("register_date")}
                >
                  登録日
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={orderBy === "update_date"}
                  direction={orderBy === "update_date" ? order : "asc"}
                  onClick={() => onRequestSort("update_date")}
                >
                  更新日
                </TableSortLabel>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {videos.length > 0 ? (
              videos.map((video) => (
                <StyledTableRow
                  key={video.id}
                  onClick={() => navigate(`/playmovie/${video.id}`)}
                  style={{ cursor: "pointer" }}
                  hover
                >
                  <StyledTableCell>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/manage/edit`, {
                          state: { id: video.id },
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell>{video.id}</StyledTableCell>
                  <ThumbnailTableCell>
                    <img
                      src={video.setting_thumbnail_url || video.thumbnail_url}
                      alt={`${video.title} Thumbnail`}
                      style={{
                        maxHeight: "30px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // 行クリックを防止
                        handleImageClick(
                          video.setting_thumbnail_url || video.thumbnail_url,
                        );
                      }}
                    />
                  </ThumbnailTableCell>
                  <StyledTableCell>{video.title}</StyledTableCell>
                  <StyledTableCell>{video.user}</StyledTableCell>
                  <StyledTableCell>{video.tag}</StyledTableCell>
                  <StyledTableCell>
                    {new Date(video.register_date).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell>
                    {video.update_date
                      ? new Date(video.update_date).toLocaleString()
                      : "-"}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <TableCell colSpan={8} align="center">
                  {isInitialState
                    ? "全件表示したい場合は条件を設定せず検索ボタンを押下してください"
                    : "検索条件の動画は見つかりませんでした"}
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>

          {/* フッターを追加 */}
          <TableFooter>
            <StyledTableRow>
              <StyledTableCell colSpan={8} align="right">
                <Typography variant="subtitle1">
                  総件数: {videos.length}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  },
);

// ソート比較関数
const compare = (a, b, property, order) => {
  let aValue = a[property];
  let bValue = b[property];

  // 日付の場合はDateオブジェクトに変換
  if (property === "register_date" || property === "update_date") {
    aValue = new Date(aValue);
    bValue = new Date(bValue);
  }

  // 文字列の場合は小文字に変換して比較
  if (typeof aValue === "string") {
    aValue = aValue.toLowerCase();
    bValue = bValue.toLowerCase();
  }

  if (aValue < bValue) {
    return order === "asc" ? -1 : 1;
  }
  if (aValue > bValue) {
    return order === "asc" ? 1 : -1;
  }
  return 0;
};

// メインコンポーネント
const Management = (getUserApiResult) => {
  // フォームの状態管理
  const [form, setForm] = useState({
    id: "", // IDを数値として扱う
    keyword: "",
    user: "",
    category: "",
  });
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [categories, setCategories] = useState([]);

  // 初期ソート状態を降順に設定
  const [order, setOrder] = useState("desc"); // 初期順序を "desc" に設定
  const [orderBy, setOrderBy] = useState("id"); // 初期ソート対象を "id" に設定

  // モーダル用の状態を追加
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // 初期表示確認用
  const [isInitialState, setIsInitialState] = useState(true);

  const navigate = useNavigate();

  // カテゴリデータの取得
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const tagListResponse = await API.graphql({
          query: queries.getTagList,
        });

        const sortedData = tagListResponse.data.getTagList.data.sort(
          (a, b) => a.id - b.id,
        );

        setCategories(sortedData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // プロパティマッピングの定義
  const propertyMapping = useMemo(
    () => ({
      id: "id",
      title: "title",
      user: "user",
      category: "tag", // 'category' を 'tag' にマッピング
    }),
    [],
  );

  // フォームクリア処理
  const handleClear = useCallback(() => {
    setForm({
      id: "",
      keyword: "",
      user: "",
      category: "",
    });
  }, []);

  /**
   * フォーム入力のハンドリングをuseCallbackでメモ化
   * @param {Object} e - イベントオブジェクト
   */
  const handleFormChange = useCallback((e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  }, []);

  /**
   * 検索ボタンのハンドリングをuseCallbackでメモ化
   */
  const handleSearch = useCallback(async () => {
    setIsInitialState(false); // 検索実行時に初期表示確認をオフ
    setLoading(true);
    try {
      // IDが入力されている場合は数値に変換
      const idValue = form.id ? parseInt(form.id, 10) : null;

      const searchVariables = {
        title: form.keyword,
        ...(form.category && { tag: form.category }),
      };

      const searchResponse = await API.graphql({
        query: queries.getSearchList,
        variables: searchVariables,
      });

      const videoList = searchResponse.data.getSearchList.video_list;

      // 【暫定対処】getSearchList が id をサポートしていないため、フロントエンドで id に基づいてフィルタリング
      const filteredVideos = idValue
        ? videoList.filter((video) => video.id === idValue)
        : videoList;

      setVideos(filteredVideos);

      // ソート状態を降順にリセット
      setOrder("desc"); // ソート順を "desc" に設定
      setOrderBy("id"); // ソート対象を "id" に設定
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setLoading(false);
    }
  }, [form]);

  /**
   * ソートリクエストのハンドリングをuseCallbackでメモ化
   * @param {string} property - ソート対象のプロパティ
   */
  const handleRequestSort = useCallback(
    (property) => {
      const mappedProperty = propertyMapping[property] || property;
      const isAscending = orderBy === mappedProperty && order === "asc";
      const newOrder = isAscending ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(mappedProperty);
    },
    [order, orderBy, propertyMapping],
  );

  // ソート済み動画のメモ化
  const sortedVideos = useMemo(() => {
    return [...videos].sort((a, b) => compare(a, b, orderBy, order));
  }, [videos, orderBy, order]);

  /**
   * 画像クリック時のハンドリング
   * @param {string} imageUrl - クリックされた画像のURL
   */
  const handleThumbnailClick = useCallback((imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  }, []);

  /**
   * モーダルを閉じるハンドリング（selectedImageのリセットはしない）
   */
  const handleCloseImageModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  /**
   * モーダルが完全に閉じた後にselectedImageをリセット
   */
  const handleImageModalExited = useCallback(() => {
    setSelectedImage("");
  }, []);

  return (
    <Box>
      <DocopaHeader
        isManagePath={true}
        cursor={true}
        handleLogoClick={() => navigate(`/manage`)}
        getUserApiResult={getUserApiResult}
      />
      <Box p={3}>
        <Grid container justifyContent="flex-end" alignItems="center" mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/manage/register")} // Registerページへの遷移
          >
            動画登録
          </Button>
        </Grid>

        {/* 検索フォームコンポーネントを<form>タグでラップ */}
        <form
          onSubmit={(e) => {
            e.preventDefault(); // デフォルトのフォーム送信を防止
            handleSearch(); // 検索処理を実行
          }}
        >
          <SearchForm
            form={form}
            onChange={handleFormChange}
            categories={categories}
            onClear={handleClear}
            onRegister={() => navigate("/manage/register")}
          />
        </form>

        {/* ローディング中はCircularProgressを表示、それ以外は動画リストを表示 */}
        {loading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          <VideoList
            videos={sortedVideos}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleRequestSort}
            navigate={navigate}
            handleImageClick={handleThumbnailClick} // 画像クリックハンドラを渡す
            isInitialState={isInitialState}
          />
        )}

        {/* 画像拡大表示用のモーダル */}
        <Dialog
          open={isModalOpen}
          onClose={handleCloseImageModal}
          TransitionComponent={Transition} // カスタムトランジションを適用
          onExited={handleImageModalExited} // トランジション終了時にselectedImageをリセット
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <img
              src={selectedImage}
              alt="Enlarged Thumbnail"
              style={{ width: "100%", height: "auto" }}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Management;
